<div class="tabs-wrapper">
  <div class="tabs-switcher d-flex flex-row">
    @for (tabTitle of titles; track $index) {
      <div
        (click)="switchToTab($index);"
        class="p-2"
        [class.px-3]="compactTabs"
        [class.px-4]="!compactTabs"
        [class.selected]="$index === currentTabIndex"
      >{{ tabTitle }}</div>
    }
  </div>
  <div class="tabs-content" #tabsContent>
    <ng-content></ng-content>
  </div>
</div>
