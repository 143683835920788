import { Component, Input } from '@angular/core';

/**
 * Provide the official MBUI Alert element.
 *
 * @see {@link https://pages.git.i.mercedes-benz.com/LUX/mui-showcase/#/elements/comp_alert}
 */
@Component({
  selector: 'app-mbui-alert',
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss',
})
export class MBUIAlertComponent {
  @Input() public variant: 'default' | 'outline' = 'default';
  @Input({ required: true }) public type!: 'error' | 'warning' | 'info' | 'success';
  @Input({ required: true }) public message!: string;
  /** Not supported yet. */
  @Input() public title?: string;
}
