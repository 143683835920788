<button
  type="button"
  class="btn btn-primary font-weight-bold"
  [disabled]="mbuiButton.disabled"
  (click)="isDropdownVisible = !isDropdownVisible"
>
  <img src="assets/icons/basket.png" srcset="assets/icons/basket@2x.png 2x" class="add-to-basket" />
  {{ mbuiButton.label }}
  <span class="ml-2">
    <img src="assets/icons/{{ !isDropdownVisible ? 'arrow_up' : 'arrow_down' }}.png" alt="Arrow">
  </span>
</button>
@if (isDropdownVisible) {
  <div class="mbui-dropdown">
    @for (mbuiDropdownItem of mbuiDropdownItems; track mbuiDropdownItem.label) {
      <div
        class="mbui-dropdown-item"
        [class.disabled]="mbuiDropdownItem.disabled"
        (click)="selectAction(mbuiDropdownItem.name)"
      >
        <span class="img-wrapper">
          <img src="assets/icons/{{ mbuiDropdownItem.icon }}.svg" alt="Button icon">
        </span>
        {{ mbuiDropdownItem.label }}
      </div>
    }
  </div>
}
