import {
  Component,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

/**
 * A component that provides an MBUI-compatible button with a dropdown menu.
 *
 * This component renders a button that, when clicked, displays a dropdown menu with a list of actions.
 * Each action in the dropdown menu has a label, an icon, and a disabled state.
 * The component also emits an event when an action is selected from the dropdown menu.
 */
@Component({
  selector: 'app-mbui-button-dropdown',
  templateUrl: './button-dropdown.component.html',
  styleUrl: './button-dropdown.component.scss',
})
export class MBUIButtonDropdownComponent {
  /** List of dropdown items, each containing a label, an icon, and a disabled state. */
  @Input() public mbuiDropdownItems: { name: 'UPGRADE_WINDOWS11' | 'RENEW_LICENSES'; label: string; icon: string; disabled: boolean }[] = [];
  /** Configuration for the main button, including its label and disabled state */
  @Input() public mbuiButton: { label: string; disabled: boolean } = { label: '', disabled: false };
  @Output() public selectedAction: EventEmitter<'UPGRADE_WINDOWS11' | 'RENEW_LICENSES'> = new EventEmitter<'UPGRADE_WINDOWS11' | 'RENEW_LICENSES'>();

  public isDropdownVisible = false;

  public selectAction(action: 'UPGRADE_WINDOWS11' | 'RENEW_LICENSES'): void {
    this.isDropdownVisible = false;
    this.selectedAction.emit(action);
  }
}
