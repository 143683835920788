<div class="carousel-wrapper">
  <div class="carousel-content" #scrollableContent>
    <ng-content></ng-content>
  </div>

  @if (hasScrolling) {
    <!-- Scroll arrow left -->
    <div
      *ngIf="currentElementIndex > 0"
      role="button"
      class="scroll-arrow scroll-arrow-left text-center"
      (click)="scrollToElement(currentElementIndex - 1)"
    >
      <img src="assets/icons/arrow_left.svg" />
    </div>
    <!-- / Scroll arrow left -->

    <!-- Scroll arrow right -->
    <div
      *ngIf="indexes && currentElementIndex < indexes.length - 1"
      role="button"
      class="scroll-arrow scroll-arrow-right text-center"
      (click)="scrollToElement(currentElementIndex + 1)"
    >
      <img src="assets/icons/arrow_right.svg" />
    </div>
    <!-- / Scroll arrow right -->

    <!-- Scroll dots / scroll indicators -->
    <div class="scroll-dots">
      @for (elementIndex of indexes; track elementIndex) {
        <span
          class="scroll-dot"
          [class.scroll-dot-active]="currentElementIndex === elementIndex"
          (click)="scrollToElement(elementIndex);"
        ></span>
      }
    </div>
  }
  <!-- / Scroll dots / scroll indicators -->
</div>
