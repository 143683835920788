import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { NGXLogger } from 'ngx-logger';

/**
 * Provide an MBUI compatible Carousel element.
 */
@Component({
  selector: 'app-mbui-carousel',
  templateUrl: './carousel.component.html',
  styleUrl: './carousel.component.scss',
})
export class MBUICarouselComponent implements AfterViewInit, AfterViewChecked {
  @ViewChild('scrollableContent') public content?: ElementRef<HTMLDivElement>;
  /**
   * List of indexes of elements inside the carousel.
   * For 4 content elements, `indexes` would be `[0, 1, 2, 3]`.
   *
   * This is needed (instead of a plain number) to be able to use it to render
   * corresponding elements in the Angular template.
   */
  public indexes?: number[];
  public currentElementIndex = 0;
  /** Whether horizontal scrolling is enabled or not (left, right, dots). */
  public hasScrolling = false;

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    private readonly logger: NGXLogger,
  ) { }

  public ngAfterViewInit(): void {
    this.logger.debug(
      'Carousel view initialized',
      this.content?.nativeElement,
      this.content?.nativeElement.children.length,
    );

    // Generate list of indexes, e. g. [0, 1, 2, 3].
    this.indexes = Array.from(Array(this.content?.nativeElement.children.length).keys());
    this.currentElementIndex = 0;

    // Explicitly detect changes because the interface changed after view init.
    this.cdRef.detectChanges();

    if (this.content) {
      const contentWidth = Array.from(this.content.nativeElement.children).reduce((n, { clientWidth }) => n + clientWidth, 0); // Sum of all child widths.

      // If the actual content width is larger than the scollable content area, enable scrolling.
      this.hasScrolling = this.content.nativeElement.clientWidth < contentWidth;

      this.cdRef.detectChanges();
    }
  }

  public ngAfterViewChecked(): void {
    if (this.indexes && this.indexes.length !== this.content?.nativeElement.children.length) {
      this.logger.debug('Number of carousel elements changed', this.indexes.length, this.content?.nativeElement.children.length);

      this.indexes = Array.from(Array(this.content?.nativeElement.children.length).keys());
      this.currentElementIndex = Math.min(this.currentElementIndex, this.indexes.length - 1);

      // Explicitly detect changes because the interface changed after view checked.
      this.cdRef.detectChanges();
    }
  }

  /**
   * Scroll to the corresponding element of the given `index` in `#scrollableContent`.
   *
   * @param index - Element index to scroll to
   */
  public scrollToElement(index: number): void {
    if (!this.content || !this.indexes) {
      this.logger.warn('Cannot scroll to element index because content or indexes are not initialized', this.content, this.indexes);
      return;
    }

    let scrollToIndex = index;
    if (scrollToIndex < 0) {
      scrollToIndex = 0;
    } else if (scrollToIndex > this.indexes.length - 1) {
      scrollToIndex = this.indexes[this.indexes.length - 1];
    }

    // Scroll to the element BEFORE the requested index to display the requested index
    // in the middle of the carousel.
    // TODO: Rework this based on element width?
    const elementOffsetLeft = scrollToIndex === 0 ? 0 : (this.content.nativeElement.children[Math.max(scrollToIndex - 1, 0)] as HTMLDivElement).offsetLeft;

    this.content.nativeElement.scrollTo({
      left: elementOffsetLeft,
      behavior: 'smooth', // Does not work if the user disabled animations in browser or OS.
    });

    this.currentElementIndex = scrollToIndex;
  }
}
