import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MBUIAlertComponent } from './components/alert/alert.component';
import { MBUIButtonDropdownComponent } from './components/button-dropdown/button-dropdown.component';
import { MBUICarouselComponent } from './components/carousel/carousel.component';
import { MBUITabsComponent } from './components/tabs/tabs.component';

@NgModule({
  declarations: [
    MBUIAlertComponent,
    MBUIButtonDropdownComponent,
    MBUICarouselComponent,
    MBUITabsComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    CommonModule,
    MBUIAlertComponent,
    MBUIButtonDropdownComponent,
    MBUICarouselComponent,
    MBUITabsComponent,
  ],
})
export class MBUIModule {
}
